// /**
//  * SEO component that queries for data with
//  *  Gatsby's useStaticQuery React hook
//  *
//  * See: https://www.gatsbyjs.org/docs/use-static-query/
//  */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ description, lang, meta, title, yoast }) {
  const metaDescription = description || yoast.yoast_wpseo_metadesc;
  const metaTitle = title || yoast.yoast_wpseo_title;

  const facebookTitle = yoast.yoast_wpseo_facebook_title || metaTitle;
  const facebookDescription = yoast.yoast_wpseo_facebook_description || metaDescription;
  const facebookImage = yoast.yoast_wpseo_facebook_image && yoast.yoast_wpseo_facebook_image.localFile ? yoast.yoast_wpseo_facebook_image.localFile.publicURL : '';

  const twitterTitle = yoast.yoast_wpseo_twitter_title || metaTitle;
  const twitterDescription = yoast.yoast_wpseo_twitter_description || metaDescription;
  const twitterImage = yoast.yoast_wpseo_twitter_image && yoast.yoast_wpseo_twitter_image.localFile ? yoast.yoast_wpseo_twitter_image.localFile.publicURL : '';


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      // titleTemplate={`%s | Greenbridge`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: facebookTitle,
        },
        {
          property: `og:description`,
          content: facebookDescription,
        },
        {
          property: `og:image`,
          content: facebookImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Greenbridge',
        },
        {
          name: `twitter:title`,
          content: twitterTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
      ].filter(itm => itm.content).concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  yoast: {}
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  yoast: PropTypes.object
}

export default SEO





// import React from "react";
// import { Helmet } from "react-helmet";

// export default props => <Yoast {...props} />;

// const Yoast = ({ yoast, wpUrl, buildUrl, pagePath }) => {
//   if (!yoast || !wpUrl || !buildUrl || !pagePath) return null;

//   let {
//     canonical_url,
//     og_description,
//     og_image,
//     og_title,
//     seo_metadesc,
//     seo_title,
//     siteName
//   } = yoast;

//   buildUrl = buildUrl ? buildUrl.replace(/\/$/, "") : buildUrl;

//   let currentUrl = buildUrl + pagePath;

//   // if there is no canonical URL in yoast, it should be set to the current url
//   if (!canonical_url) canonical_url = currentUrl;

//   // replace the WP url with gatsby url if necessary
//   if (!!canonical_url && canonical_url.includes(wpUrl))
//     canonical_url = canonical_url.replace(wpUrl, buildUrl);

//   return (
//     <Helmet>
//       {/* SEO title */}
//       {!!seo_title && seo_title !== "" && <title>{seo_title}</title>}

//       {/* SEO meta description */}
//       {!!seo_metadesc && seo_metadesc !== "" && (
//         <meta name="description" content={seo_metadesc} />
//       )}

//       {/* Canonical url */}
//       {!!canonical_url && canonical_url !== "" && (
//         <link rel="canonical" href={canonical_url} />
//       )}

//       {/* OG Site type */}
//       <meta property="og:type" content="website" />
//       {!!og_description && og_description !== "" && (
//         <meta
//           property="og:description"
//           content={og_description ? og_description : seo_metadesc}
//         />
//       )}

//       {/* OG image */}
//       {!!og_image && !!og_image.publicURL && !!buildUrl && (
//         <meta property="og:image" content={buildUrl + og_image.publicURL} />
//       )}

//       {/* OG site name */}
//       {!!siteName && <meta property="og:site_name" content={siteName} />}

//       {/* OG url */}
//       {!!currentUrl && <meta property="og:url" content={currentUrl} />}

//       {/* OG title (same as SEO title) */}
//       {!!og_title && og_title !== "" && (
//         <meta
//           property="og:title"
//           content={og_title ? og_title : seo_title || null}
//         />
//       )}

//       {/* twitter */}
//       <meta name="twitter:card" content="summary" />

//       {/* Twitter image = OG image */}
//       {!!og_image && !!og_image.publicURL && !!buildUrl && (
//         <meta
//           property="twitter:image"
//           content={buildUrl + og_image.publicURL}
//         />
//       )}

//       {/* Twitter title = og title or seo title */}
//       {!!og_title && og_title !== "" && (
//         <meta
//           property="twitter:title"
//           content={og_title ? og_title : seo_title}
//         />
//       )}
//     </Helmet>
//   );
// };